.districtSection {
  min-height: 80px;
  border-radius: 4px;
  border: solid 1px #444;
}
.areaSection {
  min-height: 240px;
  border-radius: 4px;
  border: solid 1px #444;
}
.areaTag {
  border-radius: 4px;
  background: #f5f5f5;
  transition: all 0.3s;
  color: #444;
  border: solid 1px #888;
  cursor: pointer;
}
.selected {
  border-radius: 4px;
  background: var(--primaryColor);
  color: #fff;
  transition: all 0.3s;
}
.overflowButton {
  min-width: fit-content;
  width: 20%;
  background: var(--primaryColor);
  color: #eee;
  pointer-events: auto;
  top: -1.2em;
  right: 16px;
  border: none;
  white-space: nowrap;
  transition: all 0.3s;
}
.disabledButton {
  background: #aaa;
  color: #eee6;
  pointer-events: none;
  transition: all 0.3s;
}
