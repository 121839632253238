.thBackground {
  border: solid 1px #0004;
  background: #333;
  color: #eee;
  font-weight: 500;
}
.tbodyStyle {
  border: solid 1px #0004;
  text-align: center;
}
.copied {
  z-index: 0;
  animation: fade 0.5s forwards;
}
@keyframes fade {
  from {
    right: -30%;
    opacity: 0;
  }
  to {
    right: -50%;
    opacity: 1;
  }
}
