.calendarPicker {
  width: 80%;
  margin-top: 8px;
}
.calendar {
  width: 100%;
  border-radius: 4px;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
  border-radius: 4px;
  cursor: pointer;
}
button.react-calendar__tile--active {
  background: var(--primaryColor);
  border-radius: 50%;
  height: 48px;
  width: 48px;
}

button.react-calendar__tile--active > abbr {
  color: #fff !important;
}
.timePicker {
  height: 30px;
  border-radius: 5px;
  border-width: 1px;
}
