.imageContainer {
  position: relative;
  /* overflow: hidden; */
  cursor: pointer;
  border: solid 1px #eee;
  transition: all 0.3s cubic-bezier(0.9, 1, 0.3, 1.2);
  max-height: 40vh;
  z-index: 0;
  overflow-x: hidden;
  overflow-y: visible;
}

.imageDeleteButton {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 8px;
  right: -70px;
  transition: all 0.3s cubic-bezier(0.9, 1, 0.3, 1.2);
}
.imageContainer:hover {
  transition: all 0.3s cubic-bezier(0.9, 1, 0.3, 1.2);
}
.imageContainer:hover .imageDeleteButton {
  right: 8px;
  transition: all 0.3s cubic-bezier(0.9, 1, 0.3, 1.2);
}
.imageModal {
  max-height: 95vh;
  overflow: scroll;
}
.dateContainer {
  min-height: 24px;
  background: #888;
  width: 100%;
  text-align: center;
  color: #eee;
}
.image {
  object-fit: contain;
  height: calc(100% - 28px);
  max-width: 100%;
}
.video {
  object-fit: contain;
  height: 100%;
  max-width: 100%;
}
