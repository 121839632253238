.background {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(255, 255, 255, 0.7)), url("../media/gineRoom.jpg");
  display: flex;
  justify-content: center;
  align-items: center;

  #loginSquare {
    width: 40vw;
    min-width: 600px;
    height: 40vh;
    max-width: 700px;
    max-height: 400px;
    background: rgba(32, 26, 27, 0.842);
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    #heading {
      position: absolute;
      top: -16%;
      left: -8%;

      h1 {
        color: red;
        font-weight: 1000px;
        font-size: 80px;
        text-shadow: 0 4px 4px #3339;
      }
    }

    #form {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      > .row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin: 8px 0;

        > * {
          margin: 8px 8px;
        }

        .input {
          width: 300px;
        }
      }
      .error {
        margin: -12px;
        color: red;
      }
      .back {
        color: yellow;
        cursor: pointer;
      }
    }
  }
}
