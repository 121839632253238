html {
  --viewcoHeaderHeight: 120.05px;
  --viewcoFooterHeight: 53.34px;
  --viewcoContentHeight: calc(297mm - var(--viewcoHeaderHeight) - var(--viewcoFooterHeight));
}

.viewDocumentPageContainer {
  overflow-y: scroll;
  max-height: 92vh;
  position: relative;
}
.viewDocumentPageTopRow {
  width: 100%;
  position: relative;
}
.A4paper {
  /* width: var(--A4paperWidth); */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: max-content;
  width: 100%;
  padding: 26px;
}

.formFieldsContainer {
  width: 100%;
  margin-top: 12px;
}
.formFields {
  width: 100%;
}
.A4PaperFooter {
  width: 100%;
  margin-top: 5em;
  padding-bottom: 1em;
}
.signatureButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.toggleButtonContainer {
  width: 84px;
  height: 32px;
  border-radius: 20px;
  box-shadow: inset 0 2px 6px #0004;
  padding: 4px;
  position: relative;
  align-self: center;
  overflow: hidden;
}
.toggleButton {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  box-shadow: inset 0 2px 4px #fff6;
  transition: all 0.4s cubic-bezier(0.21, 1.1, 0.66, 1.1) 0.4s;
}
.toggleButtonText {
  margin: 8px;
  font-size: 0.85em;
  white-space: nowrap;
}
.fullToggleButton {
  cursor: pointer;
  display: flex;
  position: absolute;
  transition: all 0.4s cubic-bezier(0.21, 1.1, 0.66, 1.1);
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.printButtonContainer {
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.printIconContainer {
  background: var(--primaryColor);
  box-shadow: inset 0 2px 4px #fff6;
  border-radius: 50%;
  cursor: pointer;
  height: 2.5em;
  width: 2.5em;
}
.newTemplateButtonContainer {
  height: 2.5em;
  width: 2.5em;
  box-shadow: inset 0 2px 4px #fff6;
  border-radius: 50%;
  cursor: pointer;
  background: #34f380;
}
.selectDocTypeTemplateToggle {
  border: solid 1px #444;
  padding: 4px 16px;
  width: 50%;
  cursor: pointer;
}

.amendButton {
  min-width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 8px;
}

#eachPage {
  width: 210mm;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-height: 289mm;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
  margin-bottom: 4mm;
}
.viewCoHeader {
  display: none;
}
.viewCoFooter {
  display: none;
}
#documentContent {
  width: 90%;
}
#fieldNameMargin {
  padding-top: 1px;
}
#textAreaMargin {
  margin-top: 2px;
}
#empty {
  margin-top: 2px;
}
