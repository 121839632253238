html {
  --topNavHeight: 72px;
  --pageSelectionBackground: #222;
  --ratio: 1.4142857;
  --A4paperWidth: 45vw;
  --A4paperHeight: calc(var(--A4paperWidth) * var(--ratio));
  --primaryColor: #306ef4;
  --completeColor: #b1fec6;
  --disableTextColor: #aaa;
  --primaryTextColor: #444;
  --secondaryTextColor: #eee;
  --navBackgroundColor: #444;
}

* {
  color: #444;
}
body {
  padding: 0;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}
li {
  list-style: none;
}
ul {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
  color: #444 !important;
  background: #fff;
  height: 32px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
}
a:hover {
  background: #222;
  color: #eee !important;
  font-size: 1.2em;
  transition: all 0.3s;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.flex-column-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-row-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-row-column-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-column {
  flex-direction: column;
}
.pageSelectionContainer {
  position: absolute;
  background: var(--pageSelectionBackground);
  width: 80px;
  right: -80px;
  padding: 8px 0;
  border-radius: 4px;
  z-index: 10;
  top: 0;
  max-height: 260px;
  overflow: scroll;
  box-shadow: 0 0px 4px #fff;
}
.pageSelectionItem {
  text-align: center;
  width: 100%;
  background: var(--pageSelectionBackground);
  color: #eee;
  cursor: pointer;
  transition: all 0.3s;
}
.pageSelectionItem:hover {
  background: #bbb;
  transition: all 0.3s;
}
.clickElsewhere {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
}
.chooseButton {
  margin-left: 16px;
  background: #6e757c;
  padding: 4px 16px;
  color: #eee;
  border-radius: 5px;
  cursor: pointer;
}
.closeButtonContainer {
  cursor: pointer;
  position: absolute;
  right: 24px;
  padding: 8px;
  z-index: 999;
  background: #222;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.3s;
}

.buttonHovered {
  transform: scale(1.08);
  transition: all 0.3s;
}

.selectModal {
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.selectModalContent {
  height: 90vh;
  min-width: 80vw;
  overflow: scroll;
  padding-bottom: 40px;
}

.documentSelection {
  min-width: 75vw;
  max-width: 90vw;
  min-height: 90vh;
}

.canvasOrImg {
  border: solid 1px #0004;
  height: 300px;
}
.serviceTypeText {
  top: -48px;
  right: 0;
  z-index: 1;
  background: #333;
  white-space: nowrap;
  color: #eee;
  border-radius: 4px;
  animation: fade-in 0.4s;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.informationIconContainer {
  border-radius: 50%;
  box-shadow: 0 0px 0px #0004;
  transform: translateY(0);
  transition: all 0.3s;
}
.informationIconContainer:hover {
  box-shadow: 0 4px 4px #0004;
  transform: translateY(-3px);
  transition: all 0.3s;
}
.fit-content {
  width: fit-content;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.full-size {
  height: 100%;
  width: 100%;
}
.pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.pointer-events-none {
  pointer-events: none;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}
.red {
  background: red;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.primaryColor {
  background: var(--primaryColor);
}
.round {
  border-radius: 50%;
}
.disableText {
  color: var(--disableTextColor);
  pointer-events: none;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
