.starContainer {
  height: 100%;
  min-width: 400px;
  width: 100%;
  background: linear-gradient(to bottom, #fcfcfc, #eee);
  border-radius: 4px;
  z-index: 1;
  position: relative;
}
.signatureContainer {
  height: 38vh;
  max-height: 320px;
  border: solid 1px #0005;
  width: 100%;
  position: relative;
}
