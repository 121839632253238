.cancelContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 168px;
  right: 16px;
  height: 48px;
  width: 136px;
  border-radius: 8px;
  background: #6c757d;
  box-shadow: 0 4px 4px #0003;
  transition: all 0.4s;
}
.progressDescription {
  position: absolute;
  top: 64px;
  right: 16px;
  min-width: max-content;
  transition: all 0.4s;
}
.remarkContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 104px;
  right: 16px;
  height: 48px;
  width: 136px;
  border-radius: 8px;
  background: #6c757d;
  box-shadow: 0 4px 4px #0003;
  transition: all 0.4s;
}

.openContainer {
  height: 400px;
  width: 600px;
  background: #fff;
  box-shadow: 0 4px 13px #0006;
  transition: all 0.4s;
  justify-content: flex-start;
  align-items: center;
}

.remarkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #eee;
  background: #6c757d;
  border-radius: 4px;
  transition: all 0.4s;
  cursor: pointer;
}
.openButton {
  align-self: flex-end;
  color: #444;
  background: #fff;
  transition: all 0.4s;
}
.remarkTextarea {
  padding: 4px;
  border-radius: 5px;
  border-color: #ccc;
  min-height: 100%;
  width: 100%;
  max-height: 100%;
  transition: all 0.4s;
}
.remarkTextarea:focus {
  font-size: 17px;
  transition: all 0.4s;
}
