.notification {
  --firstColor: #444;
  --secondColor: #fff;
  top: 32px;
  right: 0;
  z-index: 12;
  transition: all 0.4s;
}

.notificationContainer {
  background: var(--secondColor);
  border-radius: 6px;
  padding: 8px 0px;
  height: 0;
  transition: all 0.3s;
  box-shadow: 0 0 13px #0003;
}
.showUp {
  height: fit-content;
  transition: all 0.3s;
}
.divisionContainer {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: solid 1px #aaa;
  color: #444;
  transform: scale(1);
  box-shadow: 0 0 0px #0003;
  transition: all 0.4s;
}
.textContent {
  color: var(--firstColor);
  transition: all 0.4s;
}
.notificationContent {
  background: var(--secondColor);
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.4s;
}
.notificationContent:hover {
  background: var(--firstColor);
  transition: all 0.4s;
}
.notificationContent:hover .divisionContainer {
  color: var(--secondColor);
  border: solid 1px var(--secondColor);
  transition: all 0.4s;
}
.notificationContent:hover .textContent {
  color: var(--secondColor);
  transition: all 0.4s;
}
.notificationContent:hover .divisionContainer {
  transform: scale(1.05);
  transition: all 0.3s;
}
.notificationIconContainer {
  animation: shake 4s infinite;
}
@keyframes shake {
  3% {
    transform: rotate(-13deg);
  }
  6% {
    transform: rotate(13deg);
  }
  9% {
    transform: rotate(-13deg);
  }
  12% {
    transform: rotate(13deg);
  }
  15% {
    transform: rotate(-13deg);
  }
  18% {
    transform: rotate(13deg);
  }
  21% {
    transform: rotate(0);
  }
}
