$jira-blue: #6099f8;
$jira-white: #ffffff;
$topNavHeight: 72px;

.actions {
  :hover {
    cursor: pointer;
  }
}

.navItem {
  :hover {
    cursor: pointer;
  }
}
.navMainItem {
  width: 30%;
  min-width: 140px;
  :hover {
    cursor: pointer;
  }
}

.newDataButton {
  cursor: pointer;
}

.backButton {
  cursor: pointer;
}

.alert {
  color: red;
}
.moveButton {
  color: #eee;
  margin: 0 2px;
  cursor: pointer;
}
