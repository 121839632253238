@import "./global.module.scss";

#openedInfoBar {
  padding: 0px 20px;
  width: 300px;
  height: 100vh;
  //   border-right: #555 0.5px solid;
  box-shadow: 2px 4px 4px #0002;
  position: relative;
  transition: all 0.3s ease-out;
  white-space: nowrap;

  #indicator {
    position: absolute;
    top: 10px;
    right: -12px;
    background: $jira-blue;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  #accountInfo {
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    opacity: 1;
    overflow: hidden;
    img {
      max-width: 80px;
      margin-right: 5px;
    }
  }

  #mainContent {
    margin-top: 10px;

    * {
      margin: 4px 0;
    }

    h3 {
      margin-bottom: 10px;
      text-align: center;
    }
    .subInfo {
      flex-wrap: nowrap;
      padding-left: 16px;
    }
    .chosen {
      flex-wrap: nowrap;
      padding-left: 16px;
      background: #222;
      color: #eee !important;
      font-size: 1.2em;
    }
    transition: all 0.3s;
    max-height: 100vh;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 30vh;
  }

  #logout {
    cursor: pointer;
  }
}

#closedInfoBar {
  #accountInfo,
  #logout {
    display: none;
    transition: all 0.4s ease-out;
  }

  #mainContent {
    opacity: 0;
    display: none;
    transition: all 0.4s;
  }

  // white-space: nowrap;
  padding: 0 16px;
  width: 0px;
  height: 100vh;
  box-shadow: 2px 4px 4px #0006;
  position: relative;
  transition: all 0.4s;

  #indicator {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: -12px;
    background: $jira-white;
    color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: all 0.4s cubic-bezier(0.9, 1, 0.3, 1.2);
  }
}
