html {
  --headerHeight: 48px;
  --tableRowHeight: 40px;
  --actionContainerWidth: 80px;
  --districtCellWidth: 132px;
  --shortItemWidth: 96px;
  --longItemWidth: 240px;
  --mainItemWidth: 190px;
  --dateItemWidth: 140px;
  --normalItemWidth: 124px;
  --clientNameWidth: 200px;
  --longItemPadding: 0px 8px;
}
.maxContent {
  width: max-content;
}
.pageContainer {
  position: relative;
  width: 100%;
  height: calc(100% - var(--topNavHeight));
}
.viewCoTable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 16px;
  width: max-content;
  overflow-x: auto;
}
.tableHeader {
  margin: auto;
  background: #222;
  border-left: solid 1px #000a;
  border-right: solid 1px #000a;
  height: var(--headerHeight);
  overflow-x: auto;
}
.tableHeader div {
  color: #eee;
}
.thDistrict {
  /* flex: 1; */
  width: var(--districtCellWidth);
  height: var(--headerHeight);
  font-size: 0.9em;
  border: solid 1px #000a;
  border-top: solid 2px #000a;
}
.thItem {
  max-width: var(--normalItemWidth);
  min-width: var(--normalItemWidth);
  padding: 8px 0px;
  font-size: 0.9em;
  border: solid 1px #000a;
  border-top: solid 2px #000a;
}
.thLongItem {
  width: var(--longItemWidth);
  text-align: center;
  padding: var(--longItemPadding);
  font-size: 0.9em;
  border: solid 1px #000a;
  border-top: solid 2px #000a;
}
.thMainItem {
  width: var(--mainItemWidth);
  padding: var(--longItemPadding);
  text-align: center;
  font-size: 0.9em;
  border: solid 1px #000a;
  border-top: solid 2px #000a;
}
.thAction {
  padding: 8px 0;
  width: var(--actionContainerWidth);
  font-size: 0.9em;
  border: solid 1px #000a;
  border-top: solid 2px #000a;
}
.thDate {
  padding: 8px 0;
  width: var(--dateItemWidth);
  font-size: 0.9em;
  border: solid 1px #000a;
  border-top: solid 2px #000a;
}
.thClientName {
  padding: 8px 0;
  width: var(--clientNameWidth);
  font-size: 0.9em;
  border: solid 1px #000a;
  border-top: solid 2px #000a;
}
.thShortItem {
  padding: 8px 0;
  width: var(--shortItemWidth);
  font-size: 0.9em;
  border: solid 1px #000a;
  border-top: solid 2px #000a;
}

.tableBody {
  margin: auto;
  width: max-content;
  border-left: solid 1px #000a;
  border-right: solid 1px #000a;
  border-bottom: solid 1px #000a;
}
.tableRow {
  width: max-content;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  transform: scale(1);
}
.documentTableRow {
  width: 100%;
  flex: 1;
  text-align: center;
  transition: all 0.3s;
}

.tableRow:hover {
  transform: scale(1.01);
  background: #ccc;
  transition: all 0.3s;
}

.tdDistrict {
  height: var(--tableRowHeight);
  width: var(--districtCellWidth);
  font-size: 0.9em;
  color: #444;
  border: solid 0.5px #000a;
}
.tdItem {
  min-width: var(--normalItemWidth);
  max-width: var(--normalItemWidth);
  font-size: 0.9em;
  color: #444;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: solid 0.5px #000a;
  height: var(--tableRowHeight);
}
.tdShortItem {
  min-width: var(--shortItemWidth);
  max-width: var(--shortItemWidth);
  font-size: 0.9em;
  color: #444;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: solid 0.5px #000a;
  height: var(--tableRowHeight);
}
.tdLongItem {
  width: var(--longItemWidth);
  font-size: 0.9em;
  text-align: center;
  color: #444;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: solid 0.5px #000a;
  height: var(--tableRowHeight);
  padding: 9px 8px;
}
.tdMainItem {
  width: var(--mainItemWidth);
  font-size: 0.9em;
  padding: 9px 8px;
  text-align: center;
  color: #444;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: solid 0.5px #000a;
  height: var(--tableRowHeight);
}
.tdDateItem {
  width: var(--dateItemWidth);
  font-size: 0.9em;
  max-width: 100%;
  color: #444;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: solid 0.5px #000a;
  height: var(--tableRowHeight);
}
.tdClientNameItem {
  min-width: var(--clientNameWidth);
  max-width: var(--clientNameWidth);
  font-size: 0.9em;
  color: #444;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: solid 0.5px #000a;
  height: var(--tableRowHeight);
}
.actionRow {
  width: 100%;
  height: 80px;
  padding: 8px 0px;
  justify-content: space-between;
}
.tdActionContainer {
  height: var(--tableRowHeight);
  width: var(--actionContainerWidth);
  font-size: 0.9em;
  max-width: 100%;
  color: #444;
  border: solid 0.5px #000a;
}
.tdIconContainer {
  cursor: pointer;
  background: #0000;
  transition: all 0.3s;
  transform: scale(1);
  width: 100%;
}
.tdIconContainer:hover {
  cursor: pointer;
  /* background: #0004; */
  background: #fffe;
  transition: all 0.3s;
}
.tdIconContainer svg {
  transition: all 0.3s;
  transform: scale(1);
}
.tdIconContainer:hover svg {
  transition: all 0.3s;
  transform: scale(1.25);
}
.inputStyle {
  width: 100%;
  border: none;
  border-bottom: solid 1px #aaa;
  border-radius: 0px;
  font-size: 0.9em;
  transition: all 0.3s;
  line-height: 24px;
}
.inputStyle:focus {
  outline: none;
  font-size: 1em;
  transition: all 0.3s;
}
.districtSelectionItem {
  color: #444;
  background: #fff;
  cursor: pointer;
  transition: all 0.3s;
}
.districtSelectionItem:hover {
  background: #7f7f7f;
  color: #fff;
  transition: all 0.3s;
}
.searchInput {
  width: 280px;
  border: none;
  border: solid 1px #aaa;
  border-radius: 4px;
  padding: 8px 8px;
  font-size: 0.9em;
  transition: all 0.3s;
}
.searchInput:focus {
  outline: none;
  font-size: 1em;
  transition: all 0.3s;
}

.templateTableRow {
  width: 100%;
  flex: 1;
  text-align: center;
  transition: all 0.4s;
  cursor: pointer;
  background: #666;
  border-bottom: solid 1px #888;
}

.templateTableRow:hover {
  background: #9c9c9c;
  transition: all 0.4s;
}
.templateTdDistrict {
  height: var(--tableRowHeight);
  width: var(--districtCellWidth);
  font-size: 0.9em;
  color: #eee;
}
.templateTdItem {
  flex: 1;
  font-size: 0.9em;
  max-width: 100%;
  color: #eee;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: var(--tableRowHeight);
}
.emptyContentTemplateTableRow {
  width: 100%;
  flex: 1;
  text-align: center;
  transition: all 0.4s;
  background: #666;
  border-bottom: solid 1px #888;
}
